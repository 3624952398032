<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <LoaderBig v-if="loading === true" />

    <div v-if="loading === false" class="row">
      <div class="col-lg-12">

        <div v-if="!tags.length" class="card">
          <div class="card-body">
            <div class="text-center mt-5">
              <h4>{{$t('Tags.NoTags.Title')}}</h4>
              <small>{{$t('Tags.NoTags.Text')}}</small>
              <div class="mt-4">
                <router-link class="btn btn-success btn-lg" to="/tags/0">
                  {{$t('Tags.General.CreateTag')}}
                  <i class="ri-arrow-right-line align-middle ml-2"></i>
                </router-link>
              </div>
            </div>
            <div class="row mt-5 justify-content-between">
              <div class="col-6">
                <blockquote class="blockquote font-size-16 mb-0" v-html="$t('Tags.NoTags.Info')"></blockquote>
              </div>
              <div class="col-5 pt-2">
                <b-card header-class="bg-transparent border-info" class="border border-info">
                  <h5 class="mt-0 mb-4 text-info">
                    <i class="ri-settings-3-line mr-3"></i>
                    {{$t('General.Base.InDevelopment')}}
                  </h5>
                  <p class="card-text">
                    {{$t('Tags.NoTags.TextInDevelopment')}}
                  </p>
                </b-card>
              </div>
            </div>
          </div>
        </div>

        <div v-if="tags.length" class="card">
          <div class="card-body">
            <div class="mb-2">
              <router-link class="btn btn-success" to="/tags/0">{{$t('Tags.General.CreateTag')}}</router-link>
            </div>

            <div class="table-responsive mt-3">
              <table
                  class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;"
              >
                <thead class="thead-light">
                  <tr>
                    <th>{{ $t("Tags.Table.Name") }}</th>
                    <th>{{ $t("Tags.Table.Status") }}</th>
                    <th class="text-right" style="width: 350px;">{{ $t("Tags.Table.Action") }}</th>
                  </tr>
                </thead>
                <tbody>
                <tr
                  v-if="!tags.length"
                >
                  <th colspan="4" class="text-center">
                    {{ $t("Tags.Table.NoData") }}
                  </th>
                </tr>
                <tr v-for="(item, index) in tags" :key="index">
                  <td >
                    <span class="font-size-18">{{item.name || 'ID: ' + item.id}}</span>
                    <b-badge
                        v-if="item.public_tag_source_id	!== null && item.tag_source_id !== item.public_tag_source_id"
                        size="sm"
                        variant="danger"
                        class="ml-2 align-middle"
                        v-b-tooltip.hover.viewport = "$t('Tags.Table.MsgWrongVersionText')"
                    >
                      {{$t('Tags.Table.MsgWrongVersion')}}
                      <i class="far fa-question-circle align-middle ml-1"></i>
                    </b-badge>
                  </td>
                  <td>
                    <b-badge
                        :variant="item.status === 1 ? 'success' : 'secondary'"
                    >
                      {{ $t("Tags.Status." + item.status)}}
                    </b-badge>
                  </td>
                  <td class="text-right">

                    <b-button
                        v-if="item.public_tag_source_id	!== null && item.tag_source_id !== item.public_tag_source_id"
                        size="sm"
                        variant="success"
                        class="mr-2"
                        @click="publicationTag(item.id, item)"
                    >
                      <i class="mdi mdi-reload"></i>
                      {{$t('Tags.Table.Public')}}
                    </b-button>

                    <b-button
                        v-if="item.status === 0"
                        size="sm"
                        variant="success"
                        class="mr-2"
                        @click="publicationTag(item.id, item)"
                    >
                      <i class="mdi mdi-play"></i>
                      {{$t('Tags.Table.Public')}}
                    </b-button>
                    <b-button
                        v-if="item.status === 1"
                        size="sm"
                        variant="warning"
                        class="mr-2"
                        @click="unPublicationTag(item.id, item)"
                    >
                      <i class="mdi mdi-play"></i>
                      {{$t('Tags.Table.UnPublic')}}
                    </b-button>

                    <router-link
                        class="btn btn-primary size ml-2 btn-sm"
                        :to="'/tags/' + item.id"
                        v-b-tooltip.hover
                        :title="$t('General.Base.Edit')"
                    >
                      <i class="mdi mdi-pencil"></i>
                    </router-link>
<!--@TODO: добавить удаление тега-->
<!--                    <a-->
<!--                        href="javascript:void(0);"-->
<!--                        class="text-danger"-->
<!--                        v-b-tooltip.hover-->
<!--                        title="Удалить"-->
<!--                    >-->
<!--                      <i class="mdi mdi-trash-can font-size-20"></i>-->
<!--                    </a>-->
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import { tagsService } from '@/helpers/fakebackend/tags.service';
  import LoaderBig from "@/components/LoaderBig";

  //@TODO: add the block with info about no the project
  export default {
    components: { Layout, PageHeader, LoaderBig },
    page: {
      title: "Tags",
      meta: [{ name: "Tags" }],
    },
    data: function () {
      return {
        title: this.$t('Tags.General.Tags'),
        items: [
          {
            text: this.$t('General.Breadcrumbs.Main'),
            href: "/",
          },
          {
            text: this.$t('General.Breadcrumbs.Tags'),
            active: true,
          },
        ],
        tags:[],
        loading: true,
        projects: false
      }
    },
    computed: {
      currentProject: function() {
        return this.$store.getters['projects/getCurrentProjects']
      },
      tagsCurrentProject: function() {
        let tags = []
        let projectId = this.$store.getters['projects/getCurrentProjects']
        if (projectId === null) {
          return [];
        }

        this.tags.forEach(tag => {
          if (tag.project_id === projectId.id) {
            tags.push(tag)
          }
        })
        return tags
      }
    },
    watch: {
      currentProject: function(project) {
        this.loadTags(project.id)
      }
    },
    methods: {
      ...tagsService,
      publicationTag(tag_id, tag) {
        this.publicTag(tag_id).then(() => {
          tag.status = 1
          tag.public_tag_source_id = tag.tag_source_id
        })
      },
      unPublicationTag(tag_id, tag) {
        this.unPublicTag(tag_id).then(() => {
          tag.status = 0
        })
      },
      loadTags(project_id) {
        this.loading = true
        this.getTags({project_id: project_id}).then(result => {
          this.tags = result
          this.loading = false
        })
      }
    },
    mounted() {
      this.$store.dispatch('projects/getCurrentProjects').then((project) => {
        if (!project) {
          this.project = false
          this.loading = false
          return
        }
        this.loadTags(project.id)
        // this.project = true
        // this.getTags({project_id: project.id}).then(result => {
        //   this.tags = result
        //   this.loading = false
        // })

      })
    }
  }
</script>